<template>
  <div
      class="weekly-record"
      :class="{
         'is-active': isActive,
      }"
      v-if="value">
    <div class="progress"
         :style="`left: ${value.dietCount / 7.0 * 100}%`"
    ></div>
    <div class="weekly-record-name">
      第{{ value.weekNum + 1 }}周
    </div>
  </div>
</template>

<script>
export default {
  name: "DietWeeklyRecord",
  props: {
    value: Object,
    isActive: false,
  },


}
</script>

<style scoped>

.weekly-record {
  margin: 4px;
  background-color: #f1f1f1;
  border-radius: 8px;
  cursor: pointer;
  user-select: none;
  overflow: hidden;
  border: 2px solid transparent;
  box-sizing: border-box;
}

.weekly-record.is-active {
  font-weight: bold;
  box-shadow: 0 0 4px #f091a6;
  border: 2px solid #ff5500;
}

.weekly-record:hover {
  opacity: .7;
}

.weekly-record > div {
  display: inline-block;
  vertical-align: top;
}

.weekly-record .weekly-record-name {
  padding: 4px 8px;
  position: relative;
  z-index: 1;
}

.progress {
  background-color: #f6bbc4;
  width: 100%;
  margin-left: -100%;
  height: 100%;
  position: relative;
  z-index: 0;
}

</style>