<template>
  <div
      ref="editPanel"
      class="diet font-size-medium"
      contenteditable
      :spellcheck="false"
      v-html="dietContent"
      @input="handleInput()"
      @keyup="handleInput()"
      @click="handleMouseUp"
      @dragstart="handleDragStart"
      @dragend="handleDragEnd"
      @dblclick="handleDoubleClick"
  ></div>
</template>

<script>
import {DietReformer} from "@/components/cookerybook/basic/DietReformer";
import {DietUtils} from "@/assets/javascript/DietUtils";

export default {
  name: "DietEditor",
  props: {
    value: {
      type: Array,
      default() {
        return [];
      }
    },
    readOnly: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      foodGroupList: [],

      dietContent: '',

      isComposing: false,

      isDragging: false,

      previousResultStr: null,

      isFormatting: false,

    }
  },
  mounted() {
    this.$refs.editPanel.addEventListener('compositionstart', this.handleCompositionStart);
    this.$refs.editPanel.addEventListener('compositionend', this.handleCompositionEnd);
    this.handleInput();
  },
  watch: {
    value: {
      handler() {
        if (this.foodGroupList !== this.value) {
          this.dietContent = DietUtils.formatToString(this.value);
          //this.dietContent = '<span>123</span><span>xxxx<span>abc\nefg</span>xxxx</span><span>hij</span><div>klm</div></div><div>nop'
        }
      },
      immediate: true,
    },
    dietContent: {
      handler() {
        this.$nextTick(() => this.handleInput());
      },
      immediate: true,
    }
  },
  methods: {
    reformDiet: function (isDragged = false) {
      if(this.isFormatting) return;
      if (this.$refs.editPanel) {
        this.isFormatting = true;
        try {
          let str = DietReformer.format(this, this.$refs.editPanel, isDragged);
          let foodGroupList = DietUtils.parseString(str);
          this.foodGroupList = foodGroupList;
          if (this.dietContent !== str) {
            this.$emit('input', foodGroupList);
          }
        } finally {
          this.isFormatting = false;
        }
      }
    },
    handleInput: function (isDragged = false) {
      if (!this.isComposing && !this.isDragging) {
        this.reformDiet(isDragged);
      }
    },
    handleCompositionStart: function () {
      this.isComposing = true;
    },
    handleCompositionEnd: function () {
      this.isComposing = false;
      this.reformDiet();
    },
    handleDragStart: function () {
      this.isDragging = true;
    },
    handleDragEnd: function () {
      this.isDragging = false;
      this.reformDiet(true);
    },
    handleMouseUp: function () {
      setTimeout(() => this.handleInput(), 50);
    },
    handleDoubleClick: function () {
      console.log('double click....');
    }

  }
}
</script>

<style scoped>

.diet {
  background-color: white;
  outline: none;
  white-space: pre-wrap;
  line-height: 1.4em;
  padding: 4px 4px;
  box-sizing: border-box;
  overflow-x: hidden;
  overflow-y: scroll;
  caret-color: #ff5500;
  border: 3px solid #409EFF;
  border-radius: 8px;
}

.diet::-webkit-scrollbar {
  width: 3px;
  padding-right: 10px !important;
}

.diet::-webkit-scrollbar-button {
  visibility: hidden;
}

.diet::-webkit-scrollbar-track {
  visibility: hidden;
}

.diet::-webkit-scrollbar-thumb {
  border-radius: 4px;
  background: #bec0c0;
  visibility: hidden;
}

.diet:hover::-webkit-scrollbar-thumb {
  visibility: visible;
}

</style>
<style>

.diet .empty-line:before {
  color: #dadada;
  content: '输入:';
}


.diet div:first-child {
  margin-top: 0px !important;
}

.diet .food-definition:hover {
  background-color: #f1f1f1;
  border-radius: 4px;
}


</style>