<template>
  <div>
    <div class="diet-list-panel font-size-medium"
         v-resize="handleTableResize"
         @keyup.ctrl.right.prevent="handleKeyRight"
         @keyup.ctrl.left.prevent="handleKeyLeft">
      <div class="divider"></div>
      <div class="layout-horizontal" ref="tableTitle">
        <div class="food-group-name layout-inflexible" style="min-height: unset"></div>
        <div class="layout-flexible layout-horizontal">
          <div v-for="diet in dietList"
               :key="diet.day"
               class="diet-food-group layout-horizontal"
               :class="{
               'is-active': hoverDiet && diet.day === hoverDiet.day
             }"
               @mouseover="handleMouseOver(diet)"
               @dblclick="dblClickDiet(diet)"
               @click.stop="clickDiet(diet)"
          >
            <div class="divider divider-vertical"></div>
            <div class="layout-flexible font-align-center padding-vertical">
              <div>第{{ diet.day + 1 }}天</div>
              <div
                  v-if="cookeryBook.userId"
                  class="btn-date font-size-base font-weight-bold font-color-warning"
                  @click.stop="clickDate(diet)"
              >{{ diet.date }}<i class="fas fa-chevron-down"></i>

              </div>
            </div>
          </div>
          <div class="divider divider-vertical"></div>
        </div>
      </div>
      <div class="divider"></div>
      <div
          v-for="(foodGroup, groupIndex) in foodGroupList"
          :key="foodGroup.name">
        <div

            class="layout-horizontal"
        >
          <div
              class="food-group-name layout-vertical layout-center layout-middle padding-horizontal-large padding-vertical layout-inflexible">
            <span class="font-weight-bold">{{ foodGroup.name }}</span>
          </div>
          <div class="layout-flexible layout-horizontal">
            <div
                class="diet-food-group layout-horizontal"
                :class="{
               'is-active': hoverDiet && diet.day === hoverDiet.day
             }"
                v-for="(diet, index) in dietList"
                :key="diet.day"
                @mouseover="handleMouseOver(diet)"
                @dblclick="dblClickDiet(diet)"
                @click.stop="clickDiet(diet)"
            >
              <div class="divider divider-vertical"></div>
              <div class="padding">
                <div v-for="food in foodCellList[index][foodGroup.name]">{{ food.name }}</div>
              </div>
            </div>
            <div class="divider divider-vertical"></div>
          </div>
        </div>
        <div class="divider"></div>
      </div>
      <div class="layout-horizontal">
        <div class="food-group-name layout-inflexible" style="min-height: unset;"></div>
        <div class="layout-flexible layout-horizontal">
          <div
              class="diet-editor-container"
              v-for="diet in dietList"
              :key="diet.day"
          >
            <diet-editor
                v-if="currentDiet && diet.day === currentDiet.day"
                class="diet-editor"
                :style="`height: ${tableHeight}px; top: -${tableHeight}px;`"
                v-model="currentDiet.foodGroupList"
                @input="handleDietInput($event, diet)"

            ></diet-editor>
          </div>
        </div>
      </div>
    </div>
    <div
        class="padding-horizontal-large padding-vertical"
    >
      <span
          class="font-color-danger"
          v-if="currentDiet"
          ><span>*</span>ctrl+←和ctrl+→可以快速切换</span>
      <span v-else>双击编辑餐单</span>
    </div>
    <ys-dialog
        title="修改日期"
        :visible.sync="calendarDialogVisible"
    >
      <div class="layout-vertical layout-center">
        <calendar
            :date="dateChangingDiet ? dateChangingDiet.date : null"
            @change="handleDateChange"
        >

        </calendar>
      </div>
    </ys-dialog>
  </div>
</template>

<script>
import {TimeUtils} from "@/assets/javascript/time-utils";
import {DietUtils} from "@/assets/javascript/DietUtils";
import DietEditor from "@/components/cookerybook/DietEditor";
import httpapi from "@/assets/javascript/httpapi";
import AutosubmitHelper from "@/assets/javascript/autosubmit-helper";
import YsDialog from "@/components/wedigets/YsDialog";
import Calendar from "@/components/calendar/Calendar";

export default {
  name: "DietListPanel",
  mixins: [httpapi, AutosubmitHelper],
  components: {Calendar, YsDialog, DietEditor},
  props: {
    organizationId: Number,
    cookeryBook: Object,
    weekNum: Number,
  },
  data() {
    return {

      TimeUtils: TimeUtils,

      dietList: [],

      foodGroupList: [],

      foodCellList: [],

      hoverDiet: null,

      currentDiet: null,

      tableHeight: 0,

      /**
       * 修改日期
       */
      calendarDialogVisible: false,
      dateChangingDiet: null,

    }
  },
  computed: {
    inputParams() {
      const {cookeryBook, weekNum, organizationId} = this;
      return {
        organizationId,
        cookeryBook,
        weekNum
      };
    }
  },
  watch: {
    dietList: {
      handler: function () {
        this.updateFoodCell()
      },
      immediate: true,
    },
    cookeryBook: {
      handler: function () {
        this.updateFoodCell()
      },
      immediate: true,
    },
    inputParams: {
      handler() {
        this.loadingCode++;
        this.dietList = [];
        if (this.cookeryBook?.id && this.weekNum != null) {
          this.loadDietList();
        } else {
          for (let n = 0; n < 7; n++) {
            let diet = DietUtils.defaultDiet();
            diet.day = n;
            this.dietList.push(diet);
          }
        }
      },
      immediate: true,
    }
  },
  mounted() {
    document.addEventListener('click', this.clickOtherRegion);
  },
  methods: {
    handleMouseOver: function (diet) {
      this.hoverDiet = diet;
    },
    clickDiet: function (diet) {
      if (this.currentDiet) {
        this.setCurrentDiet(diet);
      }
    },
    dblClickDiet: function (diet) {
      this.setCurrentDiet(diet);
    },
    clickOtherRegion: function (event) {
      if (this.$el.contains(event.target)) {
        return;
      }
      this.currentDiet = null;
    },
    clickDate(diet) {
      this.dateChangingDiet = diet;
      this.calendarDialogVisible = true;
    },
    handleDateChange(date) {
      this.calendarDialogVisible = false;
      let startDate = TimeUtils.format('yyyy-MM-dd', TimeUtils.plusDays(date, -this.dateChangingDiet.day));
      this.createChangeCookeryBookStartDateTask(startDate);
    },
    handleDietInput: function (foodGroupList, diet) {
      diet.foodGroupList = foodGroupList;
      this.updateFoodCell();
      this.createSubmitDietTask(diet);
    },
    handleTableResize: function (event) {
      this.tableHeight = event.height - this.$refs.tableTitle.clientHeight;
    },
    handleKeyRight: function () {
      if (this.currentDiet) {
        let index = this.getDietIndex(this.currentDiet);
        if (index < this.dietList.length - 1) {
          this.setCurrentDiet(this.dietList[index + 1]);
        } else {
          this.setCurrentDiet(this.dietList[0]);
        }
      } else {
        this.currentDiet = this.dietList[0];
      }
    },
    handleKeyLeft: function () {
      if (this.currentDiet) {
        let index = this.getDietIndex(this.currentDiet);
        if (index > 0) {
          this.setCurrentDiet(this.dietList[index - 1]);
        } else {
          this.setCurrentDiet(this.dietList[this.dietList.length - 1]);
        }
      } else {
        this.currentDiet = this.dietList[this.dietList.length - 1];
      }
    },
    getDietIndex: function (diet) {
      for (let n = 0; n < this.dietList.length; n++) {
        if (diet.day === this.dietList[n].day) return n;
      }
      return -1;
    },
    setCurrentDiet: function (currentDiet) {
      this.updateFoodCell();
      if (this.currentDiet === currentDiet) return;
      let losedFoodGroupList = []; //缺失的食物分组
      for (let groupIndex = 0; groupIndex < this.foodGroupList.length; groupIndex++) {
        let foodGroup = this.foodGroupList[groupIndex];
        let isLosed = true;
        for (let tmp of currentDiet.foodGroupList) {
          if (tmp.name === foodGroup.name) {
            isLosed = false;
            break;
          }
        }
        if (isLosed) {
          losedFoodGroupList.push(groupIndex);
        }
      }

      for (let losedFoodGroupIndex of losedFoodGroupList) {
        let losedFoodGroup = this.foodGroupList[losedFoodGroupIndex];
        let insertPosition = 0;
        if (losedFoodGroupIndex > 0) {
          let previousFoodGroup = this.foodGroupList[losedFoodGroupIndex - 1];
          insertPosition = currentDiet.foodGroupList.length;
          for (let n = 0; n < currentDiet.foodGroupList.length; n++) {
            if (previousFoodGroup.name === currentDiet.foodGroupList[n].name) {
              insertPosition = n + 1;
              break;
            }
          }
        }
        currentDiet.foodGroupList.splice(insertPosition, 0, DietUtils.defaultFoodGroup(losedFoodGroup.name));
      }

      this.currentDiet = currentDiet;
    },
    updateFoodCell: function () {
      let dietList = this.dietList;
      let foodGroupList = [];
      let foodCellList = [];
      for (let diet of dietList) {
        if (this.cookeryBook.userId) {
          let date = TimeUtils.plusDays(this.cookeryBook.startDate, diet.day);
          diet.date = TimeUtils.format('yyyy-MM-dd', date);
        }

        let foodCell = {};
        for (let groupIndex = 0; groupIndex < diet.foodGroupList.length; groupIndex++) {
          let foodGroup = diet.foodGroupList[groupIndex];
          foodCell[foodGroup.name] = foodGroup.foodList;
          let isDuplicated = false;
          for (let tmp of foodGroupList) {
            if (tmp.name === foodGroup.name) {
              isDuplicated = true;
              break;
            }
          }
          if (isDuplicated) continue;
          let previousFoodGroup = groupIndex > 0 ? diet.foodGroupList[groupIndex - 1] : null;
          let insertPosition = 0;
          if (previousFoodGroup) {
            insertPosition = foodGroupList.length;
            for (let n = 0; n < foodGroupList.length; n++) {
              if (foodGroupList[n].name === previousFoodGroup.name) {
                insertPosition = n + 1;
                break;
              }
            }
          }
          foodGroupList.splice(insertPosition, 0, {name: foodGroup.name});
        }
        foodCellList.push(foodCell);
      }
      this.foodGroupList = foodGroupList;
      this.foodCellList = foodCellList;
    },
    createSubmitDietTask(diet) {
      this.createTask({
        tag: 'submit_diet',
        params: diet,
        runFunc: this.submitDiet,
      })
    },
    createChangeCookeryBookStartDateTask(startDate) {
      this.createTask({
        tag: 'change_start_date',
        params: startDate,
        runFunc: this.setCookeryBookStartDate,
      })
    },
    loadDietList() {
      this.$reqGet({
        path: `/cookery_book/diet/list/weekly/${this.cookeryBook.id}/${this.weekNum}`
      })
          .returnWhenCodeMatches()
          .then(res => {
            this.dietList = res.data;
          })
          .catch(() => this.$message.error('加载失败'))
    },
    async addCookeryBook(cookeryBook) {
      await this.$reqPost({
        path: `/cookery_book/${this.organizationId}/${encodeURIComponent(cookeryBook.name)}` + (this.cookeryBook.message ? `/${encodeURIComponent(this.cookeryBook.message)}` : '')
      })
          .returnWhenCodeMatches()
          .then(res => {
            cookeryBook.id = res.data.id;
            window.eventBus.$emit('update:cookery_book', {id: cookeryBook.id});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async sendCookeryBookToUser(cookeryBook) {
      await this.$reqPost({
        path: `/user_cookery_book/${cookeryBook.userId}/${this.organizationId}/${encodeURIComponent(cookeryBook.name)}` + (this.cookeryBook.message ? `/${encodeURIComponent(this.cookeryBook.message)}` : '')
      })
          .returnWhenCodeMatches()
          .then(res => {
            cookeryBook.id = res.data.id;
            window.eventBus.$emit('update:cookery_book', {id: cookeryBook.id});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    },
    async submitDiet(diet) {
      let cookeryBook = this.cookeryBook;
      if (!cookeryBook.id) {
        if (!cookeryBook.userId) {
          await this.addCookeryBook(cookeryBook);
        } else {
          await this.sendCookeryBookToUser(cookeryBook);
        }
      }
      await this.$reqPostJSON({
        path: `/cookery_book/diet/${cookeryBook.id}/${diet.day}`,
        data: {
          foodGroupList: diet.foodGroupList,
        }
      })
          .returnWhenCodeMatches()
          .then(() => {
            this.$emit('input', this.cookeryBook);
          })
          .promise();
    },
    async setCookeryBookStartDate(startDate) {
      let cookeryBook = this.cookeryBook;
      if (!cookeryBook.id) {
        if (!cookeryBook.userId) {
          await this.addCookeryBook(cookeryBook);
        } else {
          await this.sendCookeryBookToUser(cookeryBook);
        }
      }
      await this.$reqPatch({
        path: `/user_cookery_book/${cookeryBook.id}/start_date/${startDate}`
      })
          .returnWhenCodeMatches()
          .then((res) => {
            cookeryBook.startDate = res.data.startDate;
            this.updateFoodCell();
            window.eventBus.$emit('update:cookery_book', {id: cookeryBook.id});
          })
          .catch(() => this.$message.error('保存失败'))
          .promise();
    }

  }
}
</script>

<style scoped>

.diet-list-panel {
  background-color: white;
}

.food-group-name {
  background-color: #f1f1f1;
  min-height: 7vh;
  width: 56px;
  box-sizing: border-box;
}

.diet-food-group {
  width: 14.2875%;
  cursor: pointer;
}

.diet-food-group.is-active {
  background-color: #faf4f5;
}

.diet-editor {
  position: relative;
}

.diet-editor-container {
  height: 0px;
  width: 14.2875%;
}

.btn-date {
  cursor: pointer;
}

</style>