import {TimeUtils} from "@/assets/javascript/time-utils";

const TITLE_MATERIAL = "原料";

const TITLE_HOW = "做法";

const TITLE_EFFECT = "功效";

const DEFAULT_DIET = "#早餐\n#早点\n#午餐\n#午点\n#晚餐\n#晚点"

function formatToString(foodGroupList) {
    if (!foodGroupList || foodGroupList.length === 0) return DEFAULT_DIET;
    let result = '';
    for (let foodGroup of foodGroupList) {
        result += `#${foodGroup.name}\n`;
        for (let food of foodGroup.foodList) {
            result += `${food.name}\n`
        }
    }
    return result;
}

function parseString(str) {
    let lines = str.split(/[\r\n]+/);
    let foodGroupList = [];
    let curFoodGroup = null;
    let curFood = null;
    for (let n = 0; n < lines.length; n++) {
        let line = lines[n];
        if (line.startsWith('#')) { //是食物分组
            if (line.length > 1) {
                let groupName = line.substring(1);
                curFoodGroup = null;
                for(let foodGroup of foodGroupList) {
                    if(foodGroup.name === groupName) {
                        curFoodGroup = foodGroup;
                        break;
                    }
                }
                if(!curFoodGroup) {
                    curFoodGroup = {
                        name: line.substring(1),
                        foodList: [],
                    }
                    foodGroupList.push(curFoodGroup);
                }
            }
        } else if (line.startsWith(TITLE_MATERIAL)) { //原料
            if (curFood) {
                if (line.startsWith(`${TITLE_MATERIAL}：`) || line.startsWith(`${TITLE_MATERIAL}:`)) {
                    curFood.material = line.substring(TITLE_MATERIAL.length + 1).trim();
                } else {
                    curFood.material = line.substring(TITLE_MATERIAL.length).trim();
                }
            }
        } else if (line.startsWith(TITLE_HOW)) { //做法
            if (curFood) {
                if (line.startsWith(`${TITLE_HOW}：`) || line.startsWith(`${TITLE_HOW}:`)) {
                    curFood.how = line.substring(TITLE_HOW.length + 1).trim();
                } else {
                    curFood.how = line.substring(TITLE_HOW.length).trim();
                }
            }
        } else if (line.startsWith(TITLE_EFFECT)) { //功效
            if (curFood) {
                if (line.startsWith(`${TITLE_EFFECT}：`) || line.startsWith(`${TITLE_EFFECT}:`)) {
                    curFood.effect = line.substring(TITLE_EFFECT.length + 1).trim();
                } else {
                    curFood.effect = line.substring(TITLE_EFFECT.length).trim();
                }
            }
        } else { //食物
            if (line) {
                curFood = {
                    name: line.trim(),
                    material: null,
                    how: null,
                    effect: null,
                };
                if (curFoodGroup == null) {
                    for(let group of foodGroupList) {
                        if(group.name === '未分组') {
                            curFoodGroup = group;
                            break;
                        }
                    }
                    if(curFoodGroup == null) {
                        curFoodGroup = {
                            name: '未分组',
                            foodList: [],
                        }
                        foodGroupList.push(curFoodGroup);
                    }
                }
                curFoodGroup.foodList.push(curFood);
            }
        }
    }
    return foodGroupList;
}

function defaultFood(name) {
    return {
        name,
        material: null,
        how: null,
        effect: null,
    }
}

function defaultFoodGroup(name) {
    return {
        name,
        foodList: [
        ],
    }
}

function defaultDiet() {
    return {
        id: null,

        cookeryBookId: 0,

        organizationId: 0,

        userId: null,

        name: null,

        day: 0,

        date: TimeUtils.format('yyyy-MM-dd', new Date()),

        foodGroupList: [
            defaultFoodGroup('早餐'),
            defaultFoodGroup('早点'),
            defaultFoodGroup('午餐'),
            defaultFoodGroup('午点'),
            defaultFoodGroup('晚餐'),
            defaultFoodGroup('晚点')
            ],

    }
}

export const DietUtils = {

    formatToString,

    parseString,

    defaultDiet,

    defaultFoodGroup,

}