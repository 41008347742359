import en from "element-ui/src/locale/lang/en";

const startFlag = '(##start##)';
const endFlag = '(##end##)';

const PREDEFINED_TITLES = ['原料', '做法', '功效'];

/**
 * 选择范围的偏移量
 */
class Offset {

    constructor(indexes, offset) {
        this.indexes = indexes;
        this.offset = offset;
    }

    /**
     * 容器的坐标
     * @type {[]}
     */
    indexes = [];

    /**
     * 相对容器的偏移量
     * @type {number}
     */
    offset = 0;

}

/**
 * 选择范围
 */
class SelectionRange {

    constructor(startOffset, endOffset) {
        this.startOffset = startOffset;
        this.endOffset = endOffset;
    }

    /**
     * 开始位置
     * @type {Offset}
     */
    startOffset = new Offset();

    /**
     * 结束位置
     * @type {Offset}
     */
    endOffset = new Offset();

}

/**
 * 语法树节点
 */
class TreeNode {

    constructor(flag, header, content) {
        this.flag = flag;
        this.header = header;
        this.content = content;
    }

    /**
     * 标签
     * @type {string}
     */
    flag = "";

    /**
     * 标签头部
     * @type {string}
     */
    header = "";

    /**
     * 纯文字内容
     * @type {string, null}
     */
    content = null;

    /**
     * 子节点
     * @type {[]}
     */
    childrenNodes = [];

    /**
     * 父节点
     * @type {TreeNode, null}
     */
    parentNode = null;

    /**
     * 位于父节点的位置
     * @type {number}
     */
    index = 0;

    toString() {
        if (this.content) {
            return this.content;
        } else {
            let result = '';
            if (this.header) result += this.header;
            for (let childrenNode of this.childrenNodes) {
                result += childrenNode.toString();
            }
            if (this.flag) result += "</" + this.flag + ">";
            return result;
        }

    }

    getContent(withReturn = false) {
        if (this.content) {
            return this.content;
        } else {
            let result = '';
            for (let childrenNode of this.childrenNodes) {
                result += childrenNode.getContent(withReturn);
            }
            if (withReturn && this.flag === 'div') result += '\n';
            return result;
        }
    }

}

/**
 * 格式化元数据
 */
class ReformMeta {

    constructor(range, tree) {
        this.range = range;
        this.tree = tree;
    }

    /**
     * 选择的位置，格式化之后要求选择的开始位置和结束位置不变
     * @type {SelectionRange}
     */
    range = new SelectionRange();


    /**
     * 语法树
     * @type {{}}
     */
    tree = {};

}

/**
 * 获取节点相对editNode的坐标位置
 * @param editNode
 * @param node
 * @returns {*[]|null}
 */
function getNodeIndexes(editNode, node) {
    let isChildOfEditNode = false;
    let parentNode = node.parentNode;
    let currentNode = node;
    let indexes = [];
    while (parentNode) {
        let parentIndex = null;
        for (let n = 0; n < parentNode.childNodes.length; n++) {
            if (parentNode.childNodes[n] === currentNode) {
                parentIndex = n;
                break;
            }
        }
        indexes.splice(0, 0, parentIndex);
        if (parentNode === editNode) {
            isChildOfEditNode = true;
            break;
        }
        currentNode = parentNode;
        parentNode = currentNode.parentNode;
    }
    return isChildOfEditNode ? indexes : null;
}

/**
 * 获取当前选择范围
 * @param editNode
 * @returns {SelectionRange|null}
 */
function getSelectionRange(editNode) {
    let range = window.getSelection().rangeCount > 0 ? window.getSelection().getRangeAt(0) : null;
    if (range === null) return null;
    let startIndexes = getNodeIndexes(editNode, range.startContainer);
    if (startIndexes === null) return null;
    let endIndexes = range.endContainer === range.startContainer ? startIndexes : getNodeIndexes(editNode, range.endContainer);
    if (endIndexes === null) return null;
    return new SelectionRange(
        new Offset(startIndexes, range.startOffset),
        new Offset(endIndexes, range.endOffset)
    );
}

function getTreeNode(tree, indexes) {
    let treeNode = tree;
    for (let index of indexes) {
        if (!treeNode) return null;
        treeNode = treeNode.childrenNodes[index];
    }
    return treeNode;
}

function markTreeNodeWith(treeNode, offset, mark) {
    if (treeNode.content) {
        treeNode.content = treeNode.content.substring(0, offset) + mark + treeNode.content.substring(offset);
    } else {
        let markTreeNode = new TreeNode('', '', mark);
        markTreeNode.parentNode = treeNode;
        treeNode.childrenNodes.splice(offset, 0, markTreeNode);
        for (let n = 0; n < treeNode.childrenNodes.length; n++) {
            treeNode.childrenNodes[n].index = n;
        }
    }
}

function getAndClearMarkOffset(tree, mark) {
    let treeNodeStack = getTreeNodeStacks(tree);

    let selectedTreeNode = null;
    let offsetIndex = null;
    for (let treeNode of treeNodeStack) {
        if (treeNode.content) {
            offsetIndex = treeNode.content.indexOf(mark)
            if (offsetIndex >= 0) {
                selectedTreeNode = treeNode;
                selectedTreeNode.content = selectedTreeNode.content.replaceAll(mark, '');
                if (!selectedTreeNode.content) {
                    selectedTreeNode.parentNode.childrenNodes.splice(selectedTreeNode.index, 1);
                    for (let n = 0; n < selectedTreeNode.parentNode?.childrenNodes.length; n++) {
                        selectedTreeNode.parentNode.childrenNodes[n].index = n;
                    }
                    offsetIndex = selectedTreeNode.index;
                }
                break;
            }
        }
    }
    if (!selectedTreeNode) return null;

    let indexes = [];
    let parentNode = selectedTreeNode;
    while (parentNode) {
        indexes.splice(0, 0, parentNode.index);
        parentNode = parentNode.parentNode;
        if (!parentNode?.parentNode) break;
    }

    return new Offset(indexes, offsetIndex);
}

function getOffsetContainer(editNode, indexes) {
    let node = editNode;
    for (let index of indexes) {
        if (index < node.childNodes.length) {
            node = node.childNodes[index];
        } else {
            break;
        }
    }
    if (node?.childNodes.length > 0) {
        return node.childNodes[0];
    } else {
        return node;
    }
}

/**
 * 将innerHTML解析成语法树
 * @param editNode
 */
function parseTree(editNode, range, isDragged = false) {
    /**
     * 语法树节点栈
     * @type {*[]}
     */
    let treeNodeStack = [];

    let subStr = editNode.innerHTML.replaceAll('<br>', '\n');
    let regex = /<[^>\s]+(\s+[^>]+)*\s*>|<\/[^>]+\s*>/;

    let currentTreeNode = new TreeNode();
    currentTreeNode.indexes = [];


    while (subStr.length > 0) {
        let index = subStr.search(regex);
        let content;
        if (index >= 0) {
            content = subStr.substring(0, index);
            subStr = subStr.substring(index);
        } else {
            content = subStr;
            subStr = '';
        }

        if (content.length > 0) {
            let childrenTreeNode = new TreeNode('', '', content);
            childrenTreeNode.index = currentTreeNode.childrenNodes.length;
            childrenTreeNode.parentNode = currentTreeNode;
            currentTreeNode.childrenNodes.push(childrenTreeNode);
        }


        if (index < 0) { //到字符串末尾都没有

        } else if (subStr.startsWith('</')) { //遇到标签结尾
            let matchedResult = regex.exec(subStr)[0];
            let flagEndIndex = /<\/[^>\s]+/.exec(matchedResult)[0].length;
            let flag = matchedResult.substr(2, flagEndIndex - 2);
            if (flag === currentTreeNode.flag) {
                if (treeNodeStack.length > 0) currentTreeNode = treeNodeStack.pop();
            }
            subStr = subStr.substring(matchedResult.length);
        } else { //遇到标签开始
            let matchedResult = regex.exec(subStr)[0];
            let flagEndIndex = /<[^>\s]+/.exec(matchedResult)[0].length;
            let flag = matchedResult.substr(1, flagEndIndex - 1);
            treeNodeStack.push(currentTreeNode);
            //let childrenTreeNode = new TreeNode(flag, matchedResult);
            let childrenTreeNode;
            if (flag === 'font') {
                childrenTreeNode = new TreeNode('span', '<span>')
            } else {
                childrenTreeNode = new TreeNode(flag, matchedResult);
            }
            childrenTreeNode.index = currentTreeNode.childrenNodes.length;
            childrenTreeNode.parentNode = currentTreeNode;
            currentTreeNode.childrenNodes.push(childrenTreeNode);
            currentTreeNode = childrenTreeNode;
            subStr = subStr.substring(matchedResult.length);
        }
    }

    if (range) {
        let selectionStartNode = getTreeNode(currentTreeNode, range.startOffset.indexes);
        let selectionEndNode = getTreeNode(currentTreeNode, range.endOffset.indexes);
        let offset = range.startOffset.offset;
        let mark = (isDragged ? '\n' : '') + startFlag;
        if (selectionEndNode) markTreeNodeWith(selectionStartNode, offset, mark);
        offset = range.endOffset.offset;
        if (selectionStartNode && selectionStartNode === selectionEndNode) {
            if (selectionEndNode.content) {
                offset += mark.length;
            } else {
                offset += 1;
            }
        }
        if (selectionEndNode) markTreeNodeWith(selectionEndNode, offset, endFlag);
    }

    return currentTreeNode;
}

/**
 * 将editNode的innerHtml解析成元数据
 * @param content
 */
function parseReformMeta(editNode, isDragged = false) {

    let range = getSelectionRange(editNode);

    let tree = parseTree(editNode, range, isDragged);


    let reformMeta = new ReformMeta(range, tree);

    return reformMeta;
}

function pushTreeNodeToStack(treeNodeStack, treeNode) {
    treeNodeStack.push(treeNode);
    for (let childTreeNode of treeNode.childrenNodes) {
        pushTreeNodeToStack(treeNodeStack, childTreeNode);
    }
}

function getTreeNodeStacks(tree) {
    let treeNodeStacks = [];

    pushTreeNodeToStack(treeNodeStacks, tree);
    /*treeNodeStacks.push(tree);

    for (let index = 0; index < treeNodeStacks.length; index++) {
        let treeNode = treeNodeStacks[index];
        if (treeNode.childrenNodes) {
            for (let n = 0; n < treeNode.childrenNodes.length; n++) {
                let childrenNode = treeNode.childrenNodes[n];
                treeNodeStacks.push(childrenNode);
            }
        }
    }*/
    return treeNodeStacks;
}

function getNextDivIndex(childrenNodes, startIndex) {
    for (let index = startIndex; index < childrenNodes.length; index++) {
        if (childrenNodes[index].flag === 'div') return index;
    }
    return -1;
}

function appendContentTreeNode(content, parent, flag, index) {
    let treeNode = new TreeNode(flag, `<${flag}>`);
    treeNode.parentNode = parent;
    treeNode.index = index;
    parent.childrenNodes.splice(index, 0, treeNode);
    parent.childrenNodes.forEach((childrenTreeNode, childrenIndex) => childrenTreeNode.index = childrenIndex);

    let contentTreeNode = new TreeNode('', '', content);
    contentTreeNode.parentNode = treeNode;
    treeNode.childrenNodes.push(contentTreeNode);
    return treeNode;
}

function appendSpanTreeNode(content, parent) {
    return appendContentTreeNode(content, parent, 'span', parent.childrenNodes.length);
}

function appendDivTreeNode(content, parent) {
    return appendContentTreeNode(content, parent, 'div', parent.childrenNodes.length);
}

function appendBeforeSpanTreeNode(content, parent) {
    return appendContentTreeNode(content, parent, 'span', 0);
}

function appendBeforeDivTreeNode(content, parent) {
    return appendContentTreeNode(content, parent, 'div', 0);
}

function splitTreeNode(treeNode, index) {
    if (!treeNode.content) {
        let treeNode0 = new TreeNode(treeNode.flag, treeNode.header);
        let treeNode1 = new TreeNode(treeNode.flag, treeNode.header);
        for (let n = 0; n < treeNode.childrenNodes.length; n++) {
            let childrenTreeNode = treeNode.childrenNodes[n];
            if (n < index) {
                childrenTreeNode.parentNode = treeNode0;
                childrenTreeNode.index = treeNode0.childrenNodes.length;
                treeNode0.childrenNodes.push(childrenTreeNode);
            } else {
                childrenTreeNode.parentNode = treeNode1;
                childrenTreeNode.index = treeNode1.childrenNodes.length;
                treeNode1.childrenNodes.push(childrenTreeNode);
            }
        }
        treeNode0.parentNode = treeNode;
        treeNode1.parentNode = treeNode;
        treeNode0.index = 0;
        treeNode1.index = 1;
        treeNode.childrenNodes = [treeNode0, treeNode1];
        return [treeNode0, treeNode1];
    } else {
        return [];
    }
}

function reformEnters(reformMeta) {
    let treeNodeStacks = getTreeNodeStacks(reformMeta.tree);


    for (let n = 0; n < treeNodeStacks.length; n++) {
        let treeNode = treeNodeStacks[n];
        if (!treeNode.content) continue;
        if (!treeNode.content.trim()) continue;
        let splitor = /[\r\n]+/;
        let lines = treeNode.content.split(splitor);
        if (lines.length <= 1) continue;

        let splittedChildrenNodes = [];
        let divParent = treeNode; //第一个div双亲节点，或根节点
        let indexes = [treeNode.index];
        while (divParent) {
            if (divParent.flag === 'div' || divParent === reformMeta.tree) break;
            indexes.splice(0, 0, divParent.index);
            divParent = divParent.parentNode;
        }

        let beforeBrother = null;
        let afterBrother = null;
        let searchTreeNode = divParent;
        for (let i = 0; i < indexes.length - 1; i++) {
            let index = indexes[i];
            let splitted = splitTreeNode(searchTreeNode, index);
            if (beforeBrother) {
                splitted[0].parentNode.childrenNodes.splice(0, 1);
                for (let brotherIndex = 0; brotherIndex < splitted[0].parentNode.childrenNodes.length; brotherIndex++) {
                    splitted[0].parentNode.childrenNodes[brotherIndex].index = brotherIndex;
                }
                beforeBrother.childrenNodes.push(splitted[0]);
                for (let brotherIndex = 0; brotherIndex < beforeBrother.childrenNodes.length; brotherIndex++) {
                    beforeBrother.childrenNodes[brotherIndex].index = brotherIndex;
                    beforeBrother.childrenNodes[brotherIndex].parentNode = beforeBrother;
                }
            }

            if (!beforeBrother) {
                beforeBrother = splitted[0];
                afterBrother = splitted[1];
            }

            if (splitted[1].childrenNodes.length > 0) {
                searchTreeNode = splitted[1].childrenNodes[0];
                //divParent = splitted[1];
            }


        }


        if (beforeBrother == null && treeNode.index > 0) {
            beforeBrother = new TreeNode('div', '<div>');
            for (let brotherIndex = 0; brotherIndex < treeNode.index; brotherIndex++) {
                let brotherNode = treeNode.parentNode.childrenNodes[brotherIndex];
                brotherNode.parentNode = beforeBrother;
                brotherNode.index = beforeBrother.childrenNodes.length;
                beforeBrother.childrenNodes.push(brotherNode);
            }
        }
        if (afterBrother == null && treeNode.index < treeNode.parentNode.childrenNodes.length - 1) {
            afterBrother = new TreeNode('div', '<div>');
            for (let brotherIndex = treeNode.index; brotherIndex < treeNode.parentNode.childrenNodes.length; brotherIndex++) {
                let brotherNode = treeNode.parentNode.childrenNodes[brotherIndex];
                brotherNode.parentNode = afterBrother;
                brotherNode.index = afterBrother.childrenNodes.length;
                afterBrother.childrenNodes.push(brotherNode);
            }
        }


        treeNode.parentNode.childrenNodes.splice(treeNode.index, 1);


        if (beforeBrother && beforeBrother.flag !== 'div') {
            beforeBrother.flag = 'div';
            beforeBrother.header = '<div>';
        }
        if (afterBrother && afterBrother.flag !== 'div') {
            afterBrother.flag = 'div';
            afterBrother.header = '<div>'
        }

        lines.forEach((line, lineIndex) => {
            line = line.trim();
            if (lineIndex === 0 && beforeBrother) {
                appendSpanTreeNode(line, beforeBrother);
                splittedChildrenNodes.push(beforeBrother);
            } else if (lineIndex === lines.length - 1 && afterBrother) {
                appendBeforeSpanTreeNode(line, afterBrother);
                splittedChildrenNodes.push(afterBrother);
            } else {
                let divTreeNode = new TreeNode('div', '<div>');
                appendSpanTreeNode(line, divTreeNode);
                splittedChildrenNodes.push(divTreeNode);
            }
        })


        divParent.childrenNodes = splittedChildrenNodes;
        divParent.childrenNodes.forEach((childTreeNode, index) => {
            childTreeNode.parentNode = divParent;
            childTreeNode.index = index;
        });
    }

}

/**
 * 遍历语法树，将\r\n转化为div
 * @param editNode
 */
function reformEntersBak(reformMeta) {
    let treeNodeStacks = getTreeNodeStacks(reformMeta.tree);

    for (let n = 0; n < treeNodeStacks.length; n++) {
        let treeNode = treeNodeStacks[n];
        if (!treeNode.content) continue;
        let content = treeNode.content;
        let splitor = '\n';
        let childrenNodes = []; //生成的子类型
        let startIndex = 0;
        let indexOfEnter = content.indexOf(splitor);
        while (indexOfEnter >= 0 && startIndex < content.length) {
            let line = content.substring(startIndex, indexOfEnter);
            if (line) {
                let lineTreeNode = new TreeNode('', '', line);
                lineTreeNode.index = 0;

                let childrenTreeNode = new TreeNode('div', '<div>');
                childrenTreeNode.index = childrenNodes.length;
                childrenTreeNode.parentNode = treeNode;
                lineTreeNode.parentNode = childrenTreeNode;
                childrenTreeNode.childrenNodes.push(lineTreeNode);
                childrenNodes.push(childrenTreeNode);
            }
            startIndex = indexOfEnter + 1;
            indexOfEnter = content.indexOf(splitor, startIndex);
            if (indexOfEnter < 0) indexOfEnter = content.length;
        }
        if (childrenNodes.length > 0) {
            let newTreeNode = new TreeNode(treeNode.flag, treeNode.header);
            newTreeNode.childrenNodes = childrenNodes;
            newTreeNode.parentNode = treeNode.parentNode;
            newTreeNode.index = treeNode.index;
            treeNodeStacks.splice(n, 1, newTreeNode);
            if (newTreeNode.parentNode) {
                newTreeNode.parentNode.childrenNodes.splice(newTreeNode.index, 1, newTreeNode);
            } else {
                reformMeta.tree = newTreeNode;
            }
        }
    }
}


/**
 * 将两个div中间的其他节点独立为div节点
 * 简化行的表示方式，将div的行提取到根节点的直系子节点
 * @param reformMeta
 */
function simplifyLines(reformMeta) {

    let treeNodeStack = getTreeNodeStacks(reformMeta.tree);
    for (let treeNode of treeNodeStack) {
        let startIndex = 0;
        let divIndex = getNextDivIndex(treeNode.childrenNodes, startIndex);
        if (divIndex < 0 && !treeNode.flag) {
            divIndex = treeNode.childrenNodes.length;
        }
        let divChildrenNodes = [];
        while (divIndex >= 0 && startIndex < treeNode.childrenNodes.length) {
            if (divIndex > startIndex) {
                let divChildrenNode = new TreeNode('div', '<div>');
                for (let n = startIndex; n < divIndex; n++) {
                    treeNode.childrenNodes[n].index = n;
                    treeNode.childrenNodes[n].parentNode = divChildrenNode;
                    divChildrenNode.childrenNodes.push(treeNode.childrenNodes[n]);
                }
                divChildrenNodes.push(divChildrenNode);
            }
            if (divIndex < treeNode.childrenNodes.length) {
                divChildrenNodes.push(treeNode.childrenNodes[divIndex]);
            }
            startIndex = divIndex + 1;
            divIndex = getNextDivIndex(treeNode.childrenNodes, startIndex);
            if (divIndex < 0) divIndex = treeNode.childrenNodes.length;
        }
        for (let n = 0; n < divChildrenNodes.length; n++) {
            let divChildrenNode = divChildrenNodes[n];
            divChildrenNode.parentNode = treeNode;
            divChildrenNode.index = n;
        }
        if (divChildrenNodes.length > 0) treeNode.childrenNodes = divChildrenNodes;
    }

    treeNodeStack = getTreeNodeStacks(reformMeta.tree);
    let divTreeNodes = [];

    for (let treeNode of treeNodeStack) {
        if (treeNode.flag !== 'div') continue;
        let parentNode = treeNode.parentNode;
        if (parentNode) {
            parentNode.childrenNodes = [];
            parentNode.__deleted = true;
        }
    }

    for (let treeNode of treeNodeStack) {
        if (treeNode.flag !== 'div') continue;
        if (treeNode.__deleted) continue;
        let line = treeNode.getContent();


        let divTreeNode = new TreeNode('div', '<div>');
        divTreeNode.parentNode = reformMeta.tree;
        divTreeNode.index = divTreeNodes.length;

        let lineTreeNode = new TreeNode('', '', line);
        lineTreeNode.parentNode = divTreeNode;
        divTreeNode.childrenNodes.push(lineTreeNode);

        divTreeNodes.push(divTreeNode);
    }

    if (divTreeNodes.length === 0) {
        let emptyTreeNode = new TreeNode('div', '<div>');
        emptyTreeNode.parentNode = reformMeta.tree;

        let spanTreeNode = new TreeNode('span', '<span>');
        spanTreeNode.parentNode = emptyTreeNode;
        emptyTreeNode.childrenNodes.push(spanTreeNode);

        divTreeNodes.push(emptyTreeNode);
    }

    let previousLine = null;
    for (let index = 0; index < divTreeNodes.length; index++) {
        let treeNode = divTreeNodes[index];
        let line = treeNode.getContent().trim();
        if (line.startsWith(startFlag + endFlag) && index > 0 && !previousLine) {
            let content = line.substring(startFlag.length + endFlag.length);
            if (!content) continue;
            let previousTreeNode = divTreeNodes[index - 1];
            let spanTreeNode = new TreeNode('span', '<span>');
            spanTreeNode.parentNode = previousTreeNode;
            previousTreeNode.childrenNodes = [spanTreeNode];

            let markTreeNode = new TreeNode('', '', previousLine + startFlag + endFlag);
            markTreeNode.parentNode = spanTreeNode;
            spanTreeNode.childrenNodes = [markTreeNode];

            spanTreeNode = new TreeNode('span', '<span>');
            spanTreeNode.parentNode = treeNode;
            treeNode.childrenNodes = [spanTreeNode];

            let contentTreeNode = new TreeNode('', '', content);
            contentTreeNode.parentNode = spanTreeNode;
            spanTreeNode.childrenNodes = [contentTreeNode]
        }
        previousLine = line;
    }

    for (let index = divTreeNodes.length - 1; index >= 0; index--) {
        let line = divTreeNodes[index].getContent();
        if ((!line || line === '#') && divTreeNodes.length > 1) {
            divTreeNodes.splice(index, 1);
            break;
        }
    }
    for (let index = 0; index < divTreeNodes.length; index++) {
        divTreeNodes[index].index = index;
    }

    reformMeta.tree.childrenNodes = divTreeNodes;

}


function reformFoodGroup(treeNode, line, startFlagIndex, endFlagIndex) {
    let sharp = '#';
    let content = line.substring(1);
    if (endFlagIndex === 0) {
        sharp = endFlag + sharp;
    } else if (endFlagIndex > 0) {
        content = content.substring(0, endFlagIndex - 1) + endFlag + content.substring(endFlagIndex - 1);
    }
    if (startFlagIndex === 0) {
        sharp = startFlag + sharp;
    } else if (startFlagIndex > 0) {
        content = content.substring(0, startFlagIndex - 1) + startFlag + content.substring(startFlagIndex - 1);
    }

    treeNode.childrenNodes = [];
    appendSpanTreeNode(sharp, treeNode).header = '<span class="font-color-danger margin-right-small">'
    appendSpanTreeNode(content, treeNode).header = '<span class="font-weight-bold">'
    treeNode.flag = 'div';
    treeNode.header = '<div class="margin-top-large">'
}

function reformTitledParagraph(treeNode, line, title, startFlagIndex, endFlagIndex) {
    let titleSeg = title;
    let contentSeg = line.substring(title.length);
    // eslint-disable-next-line no-empty
    if (endFlagIndex < 0) {

    } else if (endFlagIndex < title.length) {
        titleSeg = titleSeg.substring(0, endFlagIndex) + endFlag + titleSeg.substring(endFlagIndex);
    } else if (endFlagIndex >= title.length) {
        let offsetIndex = endFlagIndex - title.length;
        contentSeg = contentSeg.substring(0, offsetIndex) + endFlag + contentSeg.substring(offsetIndex);
    }
    // eslint-disable-next-line no-empty
    if (startFlagIndex < 0) {

    } else if (startFlagIndex < title.length) {
        titleSeg = titleSeg.substring(0, startFlagIndex) + startFlag + titleSeg.substring(startFlagIndex);
    } else {
        let offsetIndex = startFlagIndex - title.length;
        contentSeg = contentSeg.substring(0, offsetIndex) + startFlag + contentSeg.substring(offsetIndex);
    }

    treeNode.childrenNodes = [];
    appendSpanTreeNode(titleSeg, treeNode).header = '<span class="font-weight-bold">'
    appendSpanTreeNode(contentSeg, treeNode);
    treeNode.flag = 'div';
    treeNode.header = '<div class="margin-large font-size-base" style="background: #fafafa; border-radius: 4px;">'
}


function reformLines(reformMeta) {
    for (let n = 0; n < reformMeta.tree.childrenNodes.length; n++) {
        let treeNode = reformMeta.tree.childrenNodes[n];

        let line = treeNode.getContent().trim();

        switch (line) {
            case '早餐':
            case '早点':
            case '午餐':
            case '午点':
            case '晚餐':
            case '晚点':
            case '加餐':
                line = '#' + line;
                break;
        }

        let startFlagIndex = line.indexOf(startFlag);
        let unmarkedLine = line;
        if (startFlagIndex >= 0) unmarkedLine = unmarkedLine.substring(0, startFlagIndex) + unmarkedLine.substring(startFlagIndex + startFlag.length);
        let endFlagIndex = unmarkedLine.indexOf(endFlag);
        if (endFlagIndex >= 0) unmarkedLine = unmarkedLine.substring(0, endFlagIndex) + unmarkedLine.substring(endFlagIndex + endFlag.length);

        let matchedTitle = null;
        for (let title of PREDEFINED_TITLES) {
            if (unmarkedLine.startsWith(title)) {
                matchedTitle = title;
                break;
            }
        }

        if (unmarkedLine.startsWith('#') && unmarkedLine.length > 1) {
            reformFoodGroup(treeNode, unmarkedLine, startFlagIndex, endFlagIndex);
        } else if (matchedTitle) {
            reformTitledParagraph(treeNode, unmarkedLine, matchedTitle, startFlagIndex, endFlagIndex);
        } else if (treeNode.flag === 'div') {
            if (!unmarkedLine || (!line && reformMeta.tree.childrenNodes.length === 1)) {
                treeNode.header = '<div class="empty-line">'
            } else if (unmarkedLine) {
                treeNode.header = '<div class="margin-left food-definition">'
            } else {
                treeNode.header = '<div class="margin-left">';
            }
        }
    }
    for (let index = reformMeta.tree.childrenNodes.length - 1; index >= 0; index--) {
        let line = reformMeta.tree.childrenNodes[index].getContent().trim();
        if (!line) {
            if (reformMeta.tree.childrenNodes.length > 1) {
                reformMeta.tree.childrenNodes.splice(index, 1);
            }
        }
    }
    for (let index = 0; index < reformMeta.tree.childrenNodes.length; index++) {
        reformMeta.tree.childrenNodes[index].index = index;
    }
}

export const DietReformer = {

    format(component, editNode, isDragged = false) {

        //console.log('<<<<<<<<<<<<<<<<<<<<<<<<<<');
        //console.log('innner html: ' + editNode.innerHTML);
        let reformMeta = parseReformMeta(editNode, isDragged);

        reformEnters(reformMeta);

        //console.log('<<: ' + reformMeta.tree);

        simplifyLines(reformMeta);

        reformLines(reformMeta);


        let startOffset = getAndClearMarkOffset(reformMeta.tree, startFlag);
        let endOffset = getAndClearMarkOffset(reformMeta.tree, endFlag);


        //console.log('>>: ' + reformMeta.tree);

        editNode.innerHTML = reformMeta.tree.toString();
        let range = document.createRange();
        let changeRangeFlag = false;
        if (startOffset) {
            let startContainer = getOffsetContainer(editNode, startOffset.indexes);
            if (startContainer) {
                range.setStart(startContainer, startOffset.offset);
                changeRangeFlag = true;
            }
        } else {
            let startContainer = editNode.childNodes[0];
            range.setStart(startContainer, startContainer.childNodes.length);
            changeRangeFlag = true;
        }
        if (endOffset) {
            let endContainer = getOffsetContainer(editNode, endOffset.indexes);
            if (endContainer) {
                range.setEnd(endContainer, endOffset.offset);
                changeRangeFlag = true;
            }
        }

        if (changeRangeFlag) {
            let selection = window.getSelection();
            selection.removeAllRanges();
            selection.addRange(range);
        }

        return reformMeta.tree.getContent(true);


    }


}